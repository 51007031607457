import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import uuid from 'react-uuid';

// Utilities
import getDatesRange from 'utils/getSearchDatesRange';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

const { persistAtom: persistSidebarState } = recoilPersist({
  key: 'drawerState', // this key is using to store data in local storage
  storage: isBrowser ? localStorage : null // configurate which stroage will be used to store the data
});

const { persistAtom: persistMapState } = recoilPersist({
  key: 'mapState', // this key is using to store data in local storage
  storage: isBrowser ? localStorage : null // configurate which stroage will be used to store the data
});

const fingerPrintState = atom({
  key: 'fingerPrintState',
  default: ''
});

const drawerOpenState = atom({
  key: 'drawerState',
  default: false,
  effects_UNSTABLE: [persistSidebarState]
});

const subMenuState = atom({
  key: 'subMenuState',
  default: false
});

const dashboardSubMenuState = atom({
  key: 'dashboardSubMenuState',
  default: false
});

const toolsSubMenuState = atom({
  key: 'toolsSubMenuState',
  default: false
});

const accountsSubMenu = atom({
  key: 'accountsSubMenu',
  default: false
});
const accountsNestedInvoice = atom({
  key: 'accountsNestedInvoice',
  default: false
});

const adminMenuState = atom({
  key: 'adminMenuState',
  default: 0
});

const mapState = atom({
  key: 'mapState',
  default: false,
  effects_UNSTABLE: [persistMapState]
});

const isLoggedIn = atom({
  key: 'isLoggedIn',
  default: false
});

const profileState = atom({
  key: 'profileState',
  default: null
});

const configurationState = atom({
  key: 'configurationState',
  default: null
});

const traceState = atom({
  key: 'traceState',
  default: ''
});

const notificationItemsState = atom({
  key: 'notificationItemsState',
  default: []
});

// Global State for Search
const globalFirstRun = atom({
  key: 'globalFirstRun',
  default: true
});

const globalFirstRunInvoice = atom({
  key: 'globalFirstRunInvoice',
  default: true
});

// Global State for Search
const globalSearchCustomisation = atom({
  key: 'globalSearchCustomisation',
  default: ''
});

const globalSearchCustomisationInvoice = atom({
  key: 'globalSearchCustomisationInvoice',
  default: ''
});

const globalInvoicesCustomisation = atom({
  key: 'globalInvoicesCustomisation',
  default: ''
});

const globalCreateInvoiceDateRange = atom({
  key: 'globalCreateInvoiceDateRange',
  default: 'Last 30 days'
});

const globalInvoiceDateRange = atom({
  key: 'globalInvoiceDateRange',
  default: 'Last 30 days'
});

const globalSearchBookingDateRange = atom({
  key: 'globalSearchBookingDateRange',
  default: 'Last 30 days'
});

// Global State for Invoice
const invoiceSearchBooking = atom({
  key: 'invoiceSearchBooking',
  default: false
});

const globalListCustomisation = atom({
  key: 'globalListCustomisation',
  default: { queryEnabled: true, data: [] }
});

const globalRows = atom({
  key: 'globalSearchRows',
  default: [
    {
      id: uuid(),
      field: null,
      operator: null,
      value: '',
      startDate: '',
      endDate: '',
      isValidField: false,
      isValidOperator: false,
      isValidValue: false,
      fieldTouched: false,
      operatorTouched: false,
      valueTouched: false,
      valueOptions: []
    }
  ]
});

const globalRowsInvoice = atom({
  key: 'globalRowsInvoice',
  default: [
    {
      id: uuid(),
      field: null,
      operator: null,
      value: '',
      startDate: '',
      endDate: '',
      isValidField: false,
      isValidOperator: false,
      isValidValue: false,
      fieldTouched: false,
      operatorTouched: false,
      valueTouched: false,
      valueOptions: []
    }
  ]
});

const globalDates = atom({
  key: 'globalSearchDates',
  default: getDatesRange(30, 0)
});

const globalDatesInvoice = atom({
  key: 'globalDatesInvoice',
  default: getDatesRange(30, 0)
});

const globalInvoiceDates = atom({
  key: 'globalInvoiceDates',
  // default: { from: cDefDates.from, to: gDefDates.lastOfCurrentMonth }
  default: getDatesRange(30, 0)
});

const globalSearchData = atom({
  key: 'globalSearchData',
  default: null
});

const globalSearchDataInvoice = atom({
  key: 'globalSearchDataInvoice',
  default: null
});

const globalNextToken = atom({
  key: 'globalNextToken',
  default: ''
});

const globalNextTokenInvoice = atom({
  key: 'globalNextTokenInvoice',
  default: ''
});

const globalPreviousTokens = atom({
  key: 'globalPreviousTokens',
  default: []
});

const globalPreviousTokensInvoice = atom({
  key: 'globalPreviousTokensInvoice',
  default: []
});

// Global State for User Logs
const globalUserLogsFirstRun = atom({
  key: 'globalUserLogsFirstRun',
  default: true
});

const globalUserLogsData = atom({
  key: 'globalUserLogsData',
  default: null
});

const globalUserLogsDates = atom({
  key: 'globalUserLogsDates',
  default: getDatesRange(7, 0)
});

const globalUserLogsFilter = atom({
  key: 'globalUserLogsFilter',
  default: ''
});

const globalUserLogsNextToken = atom({
  key: 'globalUserLogsNextToken',
  default: ''
});

const globalUserPreviousTokens = atom({
  key: 'globalUserPreviousTokens',
  default: []
});

const globalUserDateRangeState = atom({
  key: 'globalUserDateRangeState',
  default: 'Last 7 days'
});

// Global State for Transcations
const globalTransactionsFirstRun = atom({
  key: 'globalTransactionsFirstRun',
  default: true
});

const globalTransactionsData = atom({
  key: 'gglobalTransactionsData',
  default: null
});

const globalTransactionsDates = atom({
  key: 'globalTransactionsDates',
  default: getDatesRange(7, 0)
});

const globalTransactionsDateRangeState = atom({
  key: 'globalTransactionsDateRangeState',
  default: 'Last 7 days'
});

const globalTransactionsFilter = atom({
  key: 'globalTransactionsFilter',
  default: ''
});

const globalTransactionsNextToken = atom({
  key: 'globalTransactionsNextToken',
  default: ''
});

const globalTransactionsPreviousTokens = atom({
  key: 'globalTransactionsPreviousTokens',
  default: []
});

// Global State for System User Logs
const globalSystemLogsFirstRun = atom({
  key: 'globalSystemLogsFirstRun',
  default: true
});

// Global State for System User Logs
const globalInvoicesFirstRun = atom({
  key: 'globalInvoicesFirstRun',
  default: true
});

const globalSystemLogsDates = atom({
  key: 'globalSystemLogsDates',
  default: getDatesRange(7, 0)
});

const globalInvoicesDates = atom({
  key: 'globalInvoicesDates',
  default: getDatesRange(30, 0)
});

const globalSystemLogsFilter = atom({
  key: 'globalSystemLogsFilter',
  default: ''
});

const globalInvoicesFilter = atom({
  key: 'globalInvoicesFilter',
  default: ''
});

const globalSystemLogsData = atom({
  key: 'globalSystemLogsData',
  default: null
});

const globalSystemDateRangeState = atom({
  key: 'globalSystemDateRangeState',
  default: 'Last 7 days'
});

const globalInvoicesData = atom({
  key: 'globalInvoicesData',
  default: null
});

const globalSystemLogsNextToken = atom({
  key: 'globalSystemLogsNextToken',
  default: ''
});

const globalInvoicesNextToken = atom({
  key: 'globalInvoicesNextToken',
  default: ''
});

const globalInvoicesPreviousTokens = atom({
  key: 'globalInvoicesPreviousTokens',
  default: ''
});

const globalSystemPreviousTokens = atom({
  key: 'globalSystemPreviousTokens',
  default: []
});

const globalIsSpecificCustomisationSearch = atom({
  key: 'globalIsSpecificCustomisationSearch',
  default: true
});

const dashboardFirstRun = atom({
  key: 'dashboardFirstRun',
  default: true
});

const dashboardMetric = atom({
  key: 'dashboardMetric',
  default: null
});

const dashboardChartData = atom({
  key: 'dashboardChartData',
  default: []
});

const dashboardRawData = atom({
  key: 'dashboardRawData',
  default: []
});

const dashboardCustomisation = atom({
  key: 'dashboardCustomisation',
  default: ''
});

const dashboardDefaultData = atom({
  key: 'dashboardDefaultData',
  default: null
});

const dashboardRange = atom({
  key: 'dashboardRange',
  default: 'Last 30 days'
});

const dashboardDate = atom({
  key: 'dashboardDate',
  default: { from: '', to: '' }
});

const dashboardAscending = atom({
  key: 'dashboardAscending',
  default: ''
});

const dashboardLimit = atom({
  key: 'dashboardLimit',
  default: ''
});

const dashboardGroup = atom({
  key: 'dashboardGroup',
  default: ''
});

const dashboardOrder = atom({
  key: 'dashboardOrder',
  default: ''
});

const appUserType = atom({
  key: 'appUserType',
  default: 'eBooker'
});

const appUserAccount = atom({
  key: 'appUserAccount',
  default: ''
});

export {
  traceState,
  drawerOpenState,
  subMenuState,
  dashboardSubMenuState,
  toolsSubMenuState,
  accountsSubMenu,
  accountsNestedInvoice,
  adminMenuState,
  isLoggedIn,
  globalFirstRun,
  globalSearchCustomisation,
  globalInvoicesCustomisation,
  notificationItemsState,
  globalRows,
  invoiceSearchBooking,
  globalDates,
  globalInvoiceDates,
  globalInvoiceDateRange,
  globalCreateInvoiceDateRange,
  globalSearchData,
  globalNextToken,
  globalPreviousTokens,
  profileState,
  mapState,
  configurationState,
  globalUserLogsFirstRun,
  globalUserLogsDates,
  globalUserLogsFilter,
  globalUserLogsData,
  globalUserLogsNextToken,
  globalUserPreviousTokens,
  globalUserDateRangeState,
  globalTransactionsDateRangeState,
  globalTransactionsFirstRun,
  globalTransactionsDates,
  globalTransactionsFilter,
  globalTransactionsData,
  globalTransactionsNextToken,
  globalTransactionsPreviousTokens,
  globalSystemLogsFirstRun,
  globalInvoicesFirstRun,
  globalSystemLogsDates,
  globalInvoicesDates,
  globalSystemLogsFilter,
  globalInvoicesFilter,
  globalSystemLogsData,
  globalInvoicesData,
  globalSystemLogsNextToken,
  globalInvoicesNextToken,
  globalSystemPreviousTokens,
  globalSystemDateRangeState,
  globalInvoicesPreviousTokens,
  fingerPrintState,
  globalListCustomisation,
  globalIsSpecificCustomisationSearch,
  globalSearchCustomisationInvoice,
  globalRowsInvoice,
  globalDatesInvoice,
  globalSearchDataInvoice,
  globalNextTokenInvoice,
  globalPreviousTokensInvoice,
  globalFirstRunInvoice,
  globalSearchBookingDateRange,
  dashboardFirstRun,
  dashboardMetric,
  dashboardChartData,
  dashboardRawData,
  dashboardCustomisation,
  dashboardDefaultData,
  dashboardRange,
  dashboardDate,
  dashboardAscending,
  dashboardLimit,
  dashboardGroup,
  dashboardOrder,
  appUserType,
  appUserAccount
};
