import React from 'react';

// Global CSS
import './src/styles/global.css';
// Global Buttons CSS
import './src/styles/button.css';
// Table Design
import './src/styles/TableDesign.css';
// Sidebar Design
import './src/styles/Sidebar.css';
// React Dropdown Styles
import 'react-dropdown/style.css';
// React Mutli Dates Calendar
import './src/styles/react-multi-dates-calendar.css';
// React Date Time Picker CSSs
import './src/styles/react-datetime.css';
// SimpleBar React Pagkage CSS
import 'simplebar/dist/simplebar.min.css';
// Simplebar Custom Design
import './src/styles/Scrollbar.css';
// React Toggle Packages CSS
import 'react-toggle/style.css';
// React Loading Skelton CSS
import 'react-loading-skeleton/dist/skeleton.css';
// React Toastify CSS
import 'react-toastify/dist/ReactToastify.min.css';
// React Tabs CSS
import 'react-tabs/style/react-tabs.css';
// JSON Theme
import 'react-json-pretty/themes/monikai.css';
// Phone Number CSS
import 'react-phone-input-2/lib/style.css';
// Fonts - @fontsource
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';

// Import Provider
import Provider from './src/provider';

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>;
