import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Import from State
import { fingerPrintState } from 'state';

function Fingerprint() {
  const [, setVisitorId] = useRecoilState(fingerPrintState);
  useEffect(() => {
    // Get the visitor identifier and store in global state - Not Saving in LocalStorage as it gets manipulated
    const fpPromise = FingerprintJS.load();
    fpPromise.then((fp) => fp.get()).then((result) => setVisitorId(result.visitorId));
  });

  return <span className="hidden" />;
}

export default Fingerprint;
