exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abhi-js": () => import("./../../../src/pages/abhi.js" /* webpackChunkName: "component---src-pages-abhi-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-google-js": () => import("./../../../src/pages/google.js" /* webpackChunkName: "component---src-pages-google-js" */),
  "component---src-pages-guest-booking-js": () => import("./../../../src/pages/guest/booking.js" /* webpackChunkName: "component---src-pages-guest-booking-js" */),
  "component---src-pages-guest-index-js": () => import("./../../../src/pages/guest/index.js" /* webpackChunkName: "component---src-pages-guest-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoicing-js": () => import("./../../../src/pages/invoicing.js" /* webpackChunkName: "component---src-pages-invoicing-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-management-js": () => import("./../../../src/pages/management.js" /* webpackChunkName: "component---src-pages-management-js" */),
  "component---src-pages-password-reset-index-js": () => import("./../../../src/pages/password/reset/index.js" /* webpackChunkName: "component---src-pages-password-reset-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-report-list-index-js": () => import("./../../../src/pages/report/list/index.js" /* webpackChunkName: "component---src-pages-report-list-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-userlog-js": () => import("./../../../src/pages/userlog.js" /* webpackChunkName: "component---src-pages-userlog-js" */)
}

